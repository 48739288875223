import { useEffect, useRef, useState } from "react";
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import SPButton from "./SPButtons";

const VideoPlayer =(r) =>{
    var data = r.data;
    var fullSize = {width: "100%", margin: "0 auto"};
    var originalSize = { maxHeight: "850px", width: "100%", margin: "0 auto" };
    const [videoSize, setVideoSize] = useState(originalSize);
    const videoEl = useRef(null);
    const attemptPlay = () => {
        videoEl &&
        videoEl.current &&
        videoEl.current.play().catch(error => {
            console.error("Error attempting to play", error);
        });
    };

    useEffect(() => {
        attemptPlay();
    }, []);

    const onMouseEnterEvent =() =>{
      setVideoSize(fullSize);
    }

    const onMouseLeaveEvent=()=>{
      setVideoSize(originalSize)
    }

    return (
      <Divider align="center" type="dashed">
        <Card >
            <div>
              <video
                style={videoSize}
                playsInline
                loop
                muted
                controls
                alt={data.alt}
                src={data.url}
                ref={videoEl}
                onMouseEnter={onMouseEnterEvent}
                onMouseLeave={onMouseLeaveEvent}
              />
            </div>
            <p>
              <SPButton data={data} buttonType="SPWebModel" />
            </p>
            <p>
                <SPButton data={data} buttonType="ModelIG" />
            </p>
            <SPButton data={data} buttonType="SPIG" />
        </Card>
      </Divider>
      );
}

export default VideoPlayer;