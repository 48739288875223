import ImageComponent from './ImageComponent';
import VideoPlayer from './VideoPlayer'

const DisplayComponent =(data) =>{
    var d = data.data
    var r = null;
    if (d.fileType === "Image"){
        r = (
            <ImageComponent
                url={d.url} 
                fileType={d.fileType} 
                label={d.label}
                header={d.header} 
                referLink={d.referLink} 
                referText={d.referText}
            />
        )
    }else if  (d.fileType === "Video"){
        r = (
            <div >
                <VideoPlayer data={d} />
            </div>
        );
    }
    return (
        <div className="product-item">
            {r}
        </div>
    );
}
export default DisplayComponent;