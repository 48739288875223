import React from 'react';
import { Image } from 'primereact/image';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import SPButton from './SPButtons';


const ImageComponent = (data) => {
    return (
        <Divider align="center" type="dashed">
        <Card >
            <Image className="card"
                src={data.url}
                alt={data.fileType} 
                width='100%' 
                style={{ maxWidth:500,flex:1}}
                preview />
                <p>
                    <SPButton data={data} buttonType="SPWebModel"></SPButton>
                </p>
                <p>
                    <SPButton data={data} buttonType="ModelIG"></SPButton>
                </p>
                <p>
                    <SPButton data={data} buttonType="SPIG"></SPButton>
                </p>
                
                
        </Card>
        </Divider>
    )
            
}

export default ImageComponent;