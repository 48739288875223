import React, { useState, useEffect, useRef } from 'react';

import { DataScroller } from 'primereact/datascroller';
import { Button } from 'primereact/button';
import { DataServices } from '../services/DataServices';
import DisplayComponent from '../components/DisplayComponent';
/*
import { ProductService } from '../service/ProductService';
import './DataScrollerDemo.css';
*/
const NewPost = () => {
    const [products, setProducts] = useState([]);
    const ds = useRef(null);
    const dataService = new DataServices();
    useEffect(() => {
        dataService.getImagesJsonData().then(data => setProducts(data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const footer = <Button type="text" icon="pi pi-plus" label="Load" onClick={() => ds.current.load()} />;

    const itemTemplate = (data) => {
        return (
            <DisplayComponent data={data}/>
        )
    }

    return (
        <div className="datascroller-demo">
            <div className="card">
                <DataScroller 
                    ref={ds} 
                    value={products} 
                    itemTemplate={itemTemplate} 
                    rows={10} 
                    loader 
                    footer={footer} 
                    header="New Posts" />
            </div>
        </div>
    );
};
                
export default NewPost;