import React from 'react';
import ModelView from './ModelView';
/*
import { ProductService } from '../service/ProductService';
import './DataScrollerDemo.css';
*/
const Home = () => {
    
    return (
        <ModelView />
    );
};
                
export default Home;