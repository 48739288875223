import ShopComponent from "../components/ShopComponent";
import { DataServices } from '../services/DataServices';
import { useEffect, useState } from 'react';

const ShoppingView = () => {
    const [dataItems, setDataItems] = useState([]);

    useEffect(() => {
        const dataService = new DataServices();
        dataService.getShoppingItems().then(data => {
            setDataItems(data);
        });
    }, []);

    return (
        <div>
            <div className="p-fluid grid formgrid" >
                {dataItems.map((r, i)=>{
                    return (
                        <div key={r.ID} className="field col-12 md:col-4">
                            <ShopComponent model={r}></ShopComponent>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
                
export default ShoppingView;