import ResponsivePanel from '../components/ResponsivePanel';
import { ListBox } from 'primereact/listbox';
import { useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import Enumerable from "linq";
import { DataServices } from '../services/DataServices';
import { useEffect } from 'react';
import ShopComponent from '../components/ShopComponent';



const PlayZone = () => {
    
    var shopModel1 = {
        panelHeader:"林莎",
        responsiveClass: "",
        cardTitle: "《莎甜苦辣》Super Lisa 林莎寫真書 特裝版",
        subtitle:"", 
        coverURL: "https://store.searchpretty.com/photos/301678676_171564718770951_1797539702377507662_n.jpg",
        content: [
            {
                "buttonType":"General", 
                "referLink":"https://www.spp.com.tw/product?saleid=2110190001", 
                "label":"《莎甜苦辣》Super Lisa 林莎寫真書 特裝版"
            }
        ]
    }

    var shopModel2 = {
        panelHeader:"蕭芷渲𝐈𝐫𝐢𝐬",
        responsiveClass: "",
        cardTitle: "芷為你而閃亮 (電子書)",
        subtitle:"", 
        coverURL: "https://store.searchpretty.com/photos/301678676_171564718770951_1797539702377507662_n.jpg",
        content: [
            {
                "buttonType":"General", 
                "referLink":"https://www.books.com.tw/products/E050138806?sloc=main", 
                "label":"芷為你而閃亮 (電子書)"
            }
        ]
    }
    
    var panelModel2 = {
        panelHeader:"預訂酒店 + 機票",
        responsiveClass: "col-12 md:col-4",
        cardTitle: null,
        subtitle: null, 
        content: [
            {"buttonType":"General", "referLink":"https://hk.trip.com/", "label":"Trip.com"},
            {"buttonType":"General", "referLink":"https://www.agoda.com/", "label":"Agoda"},
            {"buttonType":"General", "referLink":"https://www.cheapoair.com/", "label":"Cheap Air"},
            {"buttonType":"General", "referLink":"https://www.booking.com/city/hk/hong-kong.htmlhttps://www.booking.com/", "label":"Booking.com"},
            {"buttonType":"General", "referLink":"https://zh.hotels.com/en/", "label":"Hotels.com"},
            {"buttonType":"General", "referLink":"https://www.skyscanner.com.hk/", "label":"Skyscanner"}
            
        ]
    }
    const [selectedCountries, setSelectedCountries] = useState(null);
    const countries = [
        { name: 'Australia', code: 'AU', tag:["Australia", "AU"] },
        { name: 'Brazil', code: 'BR', tag:["Brazil", "BR"] },
        { name: 'China', code: 'CN', tag:["China", "CN"] },
        { name: 'Egypt', code: 'EG', tag:["Egypt", "EG"] },
        { name: 'France', code: 'FR', tag:["France", "FR"] },
        { name: 'Germany', code: 'DE', tag:["Germany", "DE"] },
        { name: 'India', code: 'IN', tag:["India", "IN"] },
        { name: 'Japan', code: 'JP', tag:["Japan", "JP"] },
        { name: 'Spain', code: 'ES', tag:["Spain", "ES"] },
        { name: 'United States', code: 'US' , tag:["United States", "US"] }
    ];
    const countryTemplate = (option) => {
        return (
            <div className="country-item">
                <img alt={option.name} src="images/flag/flag_placeholder.png" 
                onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} 
                className={`flag flag-${option.code.toLowerCase()}`} />
                <div>{option.name}</div>
            </div>
        );
    }
    

    useEffect(() => {
        const dataService = new DataServices();
        dataService.getWeather().then(data => {
            console.log(data)
        });
    }, []);

    const [selectedCountry1, setSelectedCountry1] = useState(null);
    const [filteredCountries, setFilteredCountries] = useState(null);
    const searchCountry = (event) => {
        console.log(event.query.toLowerCase());
        var t = Enumerable.from(countries)
        .where(w=>{
            var a = w.tag.map(m=>m.toLowerCase());
            var b = a.filter(a1=> a1.indexOf(event.query.toLowerCase())>=0);
            return b.length>=1;
        })
        .toArray();
        console.log(t);
        setTimeout(() => {
            let _filteredCountries;
            if (!event.query.trim().length) {
                _filteredCountries = [...countries];
            }
            else {
                _filteredCountries = t;
            }

            setFilteredCountries(_filteredCountries);
        }, 250);
    }

    return (
        <div>
            <div className="p-fluid grid formgrid" >
                <div className="field col-12 md:col-4">
                    <ShopComponent model={shopModel1}></ShopComponent>
                </div>
                <div className="field col-12 md:col-4">
                    <ShopComponent model={shopModel2}></ShopComponent>
                </div>
                <div className="field col-12 md:col-4">
                    <ShopComponent model={shopModel1}></ShopComponent>
                </div>
                <div className="field col-12 md:col-4">
                    123
                </div>
                <div className="field col-12 md:col-4">
                    456
                </div>
                <div className="field col-12 md:col-4">
                    789
                </div>
            </div>
            
            
            <ResponsivePanel model={panelModel2}></ResponsivePanel>
            <h5>Advanced with Templating, Filtering and Multiple Selection</h5>
            <AutoComplete 
                value={selectedCountry1} 
                suggestions={filteredCountries} 
                completeMethod={searchCountry} 
                field="name" 
                onChange={(e) => setSelectedCountry1(e.value)} aria-label="Countries" 
            />

            <ListBox 
                value={selectedCountries} 
                options={countries} 
                onChange={(e) => setSelectedCountries(e.value)} 
                multiple 
                filter 
                optionLabel="name"
                itemTemplate={countryTemplate} style={{ width: '15rem' }} 
                listStyle={{ maxHeight: '500px' }} 
            />
        
        </div>
        
    )
    
};
                
export default PlayZone;