
export class DataServices {
    /*
    getImagesData() {
        return require('../Data/ImagesData.json');
    }

    getMenuData(){
        return require('../Data/MenuItems.json');
    }
    */
    ping = (url, fetch = window.fetch) => {
        return new Promise((res) => {
          fetch(url)
            .then(() => res(true))
            .catch(() => res(false))
        })
    };
    
    getImagesJsonData() {
        return fetch('data/ImagesDataRest.json')
        .then(res => res.json())
        .then(d => d.data.sort((a, b)=> a.id > b.id? 1:-1).reverse());
    }
    getOptionData() {
        return fetch('data/optionData.json')
        .then(res => res.json())
        .then(d => d.data);
    }
    getImagesJsonDataApi(){
        return fetch('https://store.searchpretty.com/Data/ImagesDataRest.json', {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(res => res.json())
        .then(d => d.data);
        
    }
    getWeather() {
        return fetch('https://localhost:7150/WeatherForecast').then(res => res.json()).then(d => d.data);
    }

    getProducts() {
        return fetch('data/ImagesDataRest.json').then(res => res.json()).then(d => d.data);
    }

    getShoppingItems() {
        return fetch('data/ShoppingData.json').then(res => res.json()).then(d => d.data);
    }

    getShoppingItemsDev() {
        return fetch('http://localhost:3000/Data/ShoppingData.json').then(res => res.json()).then(d => d.data);
    }


}