import { Button } from 'primereact/button';
import { SPButtonModel, SPButtonModelFunction } from '../dataModel/SPButtonModel';
/**
 * 
 * @param {*} data 
 * @returns 
 */
const SPButton =(data) =>{
    var r = null;
    
    
    var label = data.data.label;
    var buttonType = data.buttonType;
    var referLink = data.data.referLink;
    var header = data.data.header;
    
    switch (buttonType){
        case SPButtonModel.SPWebModel:
            r =  (
                <Button 
                    className="p-button-rounded p-button-warning" 
                    icon="pi pi-user" 
                    label={"Search Pretty: "+label} 
                    //onClick={()=> window.open("/model/"+header)} >
                    onClick={()=>SPButtonModelFunction.onClickModelPage(header)}>
                </Button>
            )
            break;
        case SPButtonModel.SPIG:
            r =  (
                <Button 
                className="p-button-rounded p-button-secondary" 
                icon="pi pi-instagram" 
                label="Search Pretty IG" 
                //onClick={()=> window.open("https://www.instagram.com/search.pretty.1/", "_blank")} 
                onClick={()=> SPButtonModelFunction.onClickSPIG()}
                >
                </Button>
            )
            break;
        case SPButtonModel.ModelIG:
            r =  (
                <Button className="p-button-rounded p-button-secondary"  
                icon="pi pi-instagram" 
                label={label} 
                //onClick={()=> window.open(referLink, "_blank")} >
                onClick={()=> SPButtonModelFunction.onClickCustomLink(referLink)} >

                </Button>
            )
            break;
        case SPButtonModel.General:
            r =  (
                <Button className="p-button-rounded p-button-primary"  
                icon="pi pi-user" 
                label={label} 
                //onClick={()=> window.open(referLink, "_blank")} >
                onClick={()=> SPButtonModelFunction.onClickCustomLink(referLink)} >

                </Button>
            )
            break;
        case SPButtonModel.Shopping:
            r =  (
                <Button 
                
                className="p-button-rounded p-button-info"  
                icon="pi pi-shopping-cart" 
                label={label} 
                //onClick={()=> window.open(referLink, "_blank")} >
                onClick={()=> SPButtonModelFunction.onClickCustomLink} >    
                </Button>
            )
            break;
        default:
            r =  (
                <Button className="p-button-rounded p-button-secondary"  
                icon="pi pi-instagram" 
                label="data.data.label" 
                onClick={()=> window.open("URLs", "_blank")} >

                </Button>
            );
    }
    
    return r;
};
export default SPButton;

