import Enumerable from "linq";

export default class BlGeneral {
    getModelHeaderData = (requestData) => {
        var r = Enumerable.from(requestData).where(w=>w.fileType ==="Image").groupBy(g=>g.header).select((i) => {
            return i.getSource().sort((a,b)=>a.id < b.id ? 1: -1)[0];
        }).toArray();
        return r;
    }

    getMoreDataInfiniteScroll = (requestData, infiniteData) =>{
        var existingArray = Enumerable.from(infiniteData).select(i=>i.id).toArray();
        var innerjoin = requestData.filter(f=>{ 
            return !(existingArray.indexOf(f.id) >= 0);
        });
        var takeN = Enumerable.from(innerjoin).take(10).toArray();
        var appendN = infiniteData.concat(takeN);
        return appendN;
    }

    getDataBySearchItem = (requestData, searchItem) => {
        var r = Enumerable.from(requestData).where(w=> w.header===searchItem).select((i) => {
            return i;
        }).toArray();
        return r;
    }
};

