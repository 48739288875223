export class SPButtonModel {
    static SPWebModel = "SPWebModel";
    static SPIG = "SPIG";
    static ModelIG = "ModelIG";
    static General = "General";
    static Shopping = "Shopping";

    constructor(){
        this.buttonType = null;
        this.className = null;
        this.icon = null
        this.label = null
        this.onClick = null
    }
    /*
    constructor(buttonType, className, icon, label, onClick) {
      this.buttonType = buttonType;
      this.className = className;
      this.icon = icon;
      this.label = label;
      this.onClick = onClick;
    }
    */
}

export class SPButtonModelFunction {
    static onClickModelPage=(modelPage)=> {return window.open("/model/"+modelPage)}
    static onClickSPIG=()=> {return window.open("https://www.instagram.com/search.pretty.1/", "_blank")}
    static onClickCustomLink=(referLink)=> {return window.open(referLink, "_blank")}
}