import { Card } from 'primereact/card';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import SPButton from './SPButtons';
import { Image } from 'primereact/image';
import React from 'react';

const ShopComponent = (param) => {
    var model = param.model;
    const template = (options) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} pl-1`;
        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                </button>
                <Ripple />
                <span className={titleClassName}>
                    <h2>
                        {model.panelHeader}
                    </h2>
                </span>
            </div>
        )
    }
    return (
        <Panel className={model.responsiveClass} headerTemplate={template} toggleable>
            <Card title={model.cardTitle} subTitle={model.subtitle} >
            <Image className="card"
                src={model.coverURL}
                alt="Image" 
                width='100%' 
                style={{ maxWidth:500,flex:1}}
                />
                
                <p style={{lineHeight: '2.5'}}>   
                {model.content.map((r, i)=>{
                    return (
                        <React.Fragment key={r.ID}>
                            <SPButton data={r} buttonType={r.buttonType} />
                        </React.Fragment>
                    );
                })}
                </p>        
            </Card>
        </Panel>
    )
    
};
                
export default ShopComponent;