import { Card } from 'primereact/card';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import SPButton from './SPButtons';

const ResponsivePanel = (param) => {
    var model = param.model;

    const template = (options) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} pl-1`;
        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                </button>
                <Ripple />
                <span className={titleClassName}>
                    {model.panelHeader}
                </span>
            </div>
        )
    }
    return (
        <Panel className={model.responsiveClass} headerTemplate={template} toggleable>
            <Card title={model.cardTitle} subTitle={model.subtitle} >
                <p style={{lineHeight: '2.5'}}>   
                {model.content.map((r, i)=>{
                    return (
                        <p>
                            <SPButton data={r} buttonType={r.buttonType} />
                        </p> 
                    );
                })}
                </p>        
            </Card>
        </Panel>
    )
    
};
                
export default ResponsivePanel;