import React, { useState, useEffect } from 'react';
import { DataServices } from '../services/DataServices';
import DisplayComponent from '../components/DisplayComponent';
import BlGeneral from '../businessLogic/BlGeneral';
import { DataView } from 'primereact/dataview';

const ModelView = () => {
    const [products, setProducts] = useState([]);
    //const ds = useRef(null);
    const dataService = new DataServices();
    const bl = new BlGeneral();
    
    useEffect(() => {
        dataService.getImagesJsonData().then(data => {
            var d = bl.getModelHeaderData(data)
            return setProducts(d);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //const footer = <Button type="text" icon="pi pi-plus" label="Load" onClick={() => ds.current.load()} />;

    const itemTemplate = (data) => {
        return (
            <DisplayComponent data={data} displayView="Grid"/>
        )   
    }

    return (
        <div className="datascroller-demo">
            <div className="card">
                <DataView 
                    value={products} 
                    layout={"grid"} 
                    itemTemplate={itemTemplate}
                />
            </div>
        </div>
    );
};
                
export default ModelView;