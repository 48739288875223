
import './App.css';
import {
  BrowserRouter as Router,
  Routes, 
  Route,
  Navigate,
  useParams
} from "react-router-dom";
import NewPost from './pages/NewPost';
//import AppLogo from './images/Logo2BlackYellow.png'
import AppLogo from './images/logo.PNG'
import { Menubar } from 'primereact/menubar';
import Home from './pages/Home';
import React from 'react';
//import { InputText } from 'primereact/inputtext';
import ModelView from './pages/ModelView';
import DataByModel from './pages/DataByModel';
import PlayZone from './pages/PlayZone';
import ShoppingView from './pages/ShoppingView';

import "primeicons/primeicons.css";      
import "primereact/resources/themes/md-dark-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import 'primeflex/primeflex.css';



function Child() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let {igName} = useParams();
  return (
    <DataByModel data={igName} />
  );

}

const goToModelView=()=>{
  window.location = '/ModelView';  
}
const goToHome =() =>{
  window.location = '/Home';  
}
const goToNewPosts =() =>{
  window.location = '/newPosts';  
}
function App() {
  //const menu = useRef(null);
  const start = <img 
    alt="logo" 
    src={AppLogo} 
    //onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} 
    height="40" 
    className="mr-2">
  </img>;
  //const end = <InputText placeholder="Search" type="text" />;
  const items = [
    {
      label: 'Home',
      icon: 'pi pi-home',
      command: () => {goToHome()}
    },
    {
      label: 'New Posts',
      icon: 'pi pi-bell',
      command: () => {goToNewPosts()}
    },
    {
      label: 'Models',
      icon: 'pi pi-table',
      command: () => {goToModelView()}
    }
  ];
  /*
  const items1 = [
    {
      label: 'Posts',
      items: [
          {
              label: 'Home',
              icon: 'pi pi-home',
              command: () => {goToHome()}
          },
          {
              label: 'New Posts',
              icon: 'pi pi-bell',
              command: () => {
                goToNewPosts()
              }
          },
          {
            label: 'Models',
            icon: 'pi pi-table',
            command: () => {
              goToModelView()
            }
        }
      ]
  },
  {
      label: 'Search Buy',
      items: [
          {
              label: 'To be release',
              icon: 'pi pi-external-link',
              url: 'https://www.searchpretty.com/'
          }
      ]
  }
  ]
  */
  return (
    <div className="App">
      <div className="card">
        <Menubar model={items} start={start} />
      </div>
      <Router>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/model/:igName" element={<Child />} />
            <Route path="/newPosts" element={<NewPost />} />
            <Route path="/ModelView" element={<ModelView />} />
            <Route path="/ShoppingView" element={<ShoppingView />} />
            <Route path="/PlayZone" element={<PlayZone />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
      
      {/*<ImageDataView />*/}
      
      {/*
      <header className="App-header">
      
      
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      */}
    </div>
  );
  
}


export default App;
